import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { AokPodcastClient, AokRouteDataPipeModule, AokSvgIconComponent } from '@aok/common';
import {
  AokFooterModule,
  AokPaginationModule,
  AokPodcastTeaserModule,
  FeedbackButtonModule,
  HeaderModule,
  InfoBannerComponent,
} from '@aok/components';
import { BehaviorSubject } from 'rxjs';
import { AppConfig } from '../../../config/app.config';

@Component({
  selector: 'aok-cockpit-podcasts',
  styleUrls: ['./podcasts.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './podcasts.component.html',
  standalone: true,
  imports: [
    CommonModule,
    AokRouteDataPipeModule,
    AokPodcastTeaserModule,
    AokPaginationModule,
    FeedbackButtonModule,
    AokFooterModule,
    HeaderModule,
    InfoBannerComponent,
    AokSvgIconComponent,
  ],
})
export class PodcastsComponent {
  private _numberPodcasts = new BehaviorSubject<number>(null);

  get numberPodcasts(): number {
    return this._numberPodcasts.getValue();
  }

  constructor(private client: AokPodcastClient) {
    this.client.list({ page_size: 50, offset: 0 }).subscribe((podcasts) => {
      this._numberPodcasts.next(podcasts.episodes.length);
    });
  }

  public navigateToAokPodcasts(): void {
    window.open(AppConfig.aokPodcastsLink, '_blank');
  }
}
