<div *ngIf="showJumpTo" class="aok-container">
  <aok-to-top-scroller></aok-to-top-scroller>
</div>

<ng-container>
  <ng-container *ngTemplateOutlet="footerSupport"></ng-container>
  <ng-container *ngIf="sortByOrder(linksChange | async | keyvalue) as entries">
    <div class="aok-bg-deep-green-1">
      <div class="aok-container flex">
        <div class="footer-header grid-2">
          <ng-container *ngTemplateOutlet="footerHeader"></ng-container>
        </div>
      </div>

      <div class="aok-container groups">
        <ng-container *ngFor="let entry of entries">
          <ng-container *ngIf="checkGroupVisibility(entry.value)">
            <ng-container
              *ngTemplateOutlet="
                footerLinkGroup;
                context: {
                  group: resolveLinkGroup(entry.key),
                  links: entry.value
                }
              "
            ></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<!--This part is for the Support-->
<ng-template #footerSupport>
  <ng-container *ngIf="contactChange | async as contact">
    <div class="aok-bg-green">
      <div class="aok-container flex-column tw-pt-8 tw-pb-8">
        <div class="tw-flex tw-justify-between">
          <div class="tw-flex-auto">
            <h5 class="aok-bold">{{ contact.displayTitle }}</h5>

            <div class="grid-3">
              <div *ngIf="keycloak?.getKeycloakInstance()?.authenticated">
                <a *ngIf="contact?.email" href="mailto:{{ contact.email?.value || contact.email }}">
                  <aok-svg-icon name="mail">
                    <title>E-Mail Kontakt</title>
                  </aok-svg-icon>
                  <span>{{ contact.email?.title || contact.email }}</span>
                </a>
              </div>

              <div>
                <a *ngIf="contact?.tel" href="tel:{{ contact.tel?.value || contact.tel }}">
                  <aok-svg-icon name="phone">
                    <title>Telefon Kontakt</title>
                  </aok-svg-icon>
                  <span>{{ contact.tel?.title || contact.tel }}</span>
                </a>
              </div>

              <div *ngIf="keycloak?.getKeycloakInstance()?.authenticated">
                <a *ngIf="contact?.feedback" href="javascript:void(0)" (click)="clickFeedbackButton()">
                  <aok-svg-icon name="text-chat_bubble">
                    <title>Feedback senden</title>
                  </aok-svg-icon>
                  <span>{{ contact.feedback.title }}</span>
                </a>
              </div>
            </div>
          </div>

          <img
            src="assets/img/tuvit-badge.svg"
            height="210"
            width="140"
            alt="TÜVIT-Zertifikat für Datenschutzrelevante Prozesse innerhalb des Arztportals"
          />
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<!--This part is for the headline and the copyright sign-->
<ng-template #footerHeader>
  <h3>arztportal-niedersachsen.de</h3>
  <label class="aok-text-l3">© {{ currentYear }} AOK Niedersachsen</label>
</ng-template>

<!--This part is for to groups and links-->
<ng-template #footerLinkGroup let-group="group" let-links="links">
  <div class="aok-footer-group aok-footer-link-group">
    <h5 class="aok-footer-group-title aok-bold">{{ group.displayTitle }}</h5>
    <ul class="aok-footer-group-links">
      <ng-container *ngFor="let link of links">
        <li *ngIf="checkLinkVisibility(link)">
          <a
            *ngIf="link.callback; else linkTemplate"
            class="aok-link-1 secondary"
            aokFocusable
            [attr.data-testid]="link.testId"
            (click)="link.callback()"
            (keyup.enter)="link.callback()"
          >
            {{ link.name }}
          </a>

          <ng-template #linkTemplate>
            <a
              class="aok-link-1 secondary"
              aokFocusable
              [attr.data-testid]="link.testId"
              [target]="link?.target ?? '_self'"
              [routerLinkOrHref]="getLinkUrl(link)"
            >
              {{ link.name }}
            </a>
          </ng-template>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
